<template>
  <layout title="Delivery" :options="false">
    <div class="expande-horizontal wrap">
      <v-flex xs12 md3 class="pa-3">
        <ListAreas
          :areas="areas"
          :selectFeature="selectFeature"
          :updateAreas="updateAreas"
          :confirmRemoveArea="confirmRemoveArea"
          :openModalCreateAreaByKm="openModalCreateAreaByKm"
          :openModalFindStore="openModalFindStore"
          :default_address_store="default_address_store"
        />
        <ModalFindStore
          ref="modalFindStore"
          :saveStoreLocation="saveStoreLocation"
        />
      </v-flex>
      <v-flex xs12 md9 class="pa-3">
        <div id="map"></div>
      </v-flex>
      <ModalCreateArea
        :saveArea="saveArea"
        :addressStore="default_address_store"
        ref="modalCreateArea"
      />
      <!-- <ModalViewArea /> -->
    </div>
  </layout>
</template>

<script>
import ListAreas from "./componentesDeliveryConfig/ListAreas.vue";
import ModalCreateArea from "./componentesDeliveryConfig/ModalCreateArea.vue";
import ModalFindStore from "./componentesDeliveryConfig/ModalFindStore.vue";
import { mapActions } from "vuex";
export default {
  name: "MapRocket",
  data() {
    return {
      center: [-47.8815, -15.7942],
      zoom: 10,
      areas: [],
      areasGeojson: {
        type: "FeatureCollection",
        features: []
      },
      addAreaDialog: false,
      editAreaDialog: false,
      selectedArea: null,
      map: null,
      draw: null,
      default_address_store: {}
    };
  },
  components: {
    ListAreas,
    ModalCreateArea,
    ModalFindStore
  },
  methods: {
    ...mapActions(["createGlobalMessage", "createConfirmAction"]),
    saveStoreLocation(address) {
      this.$store.dispatch("enableLoading");
      this.$run("marketplaces/set-default-address-store", {
        address: address
      })
        .then(() => {
          this.$store.dispatch("disableLoading");
          this.createGlobalMessage({
            timeout: 6000,
            type: "sucess",
            message: "A localização da loja foi salva!"
          });
          this.getDeliveryConfig();
        })
        .catch(e => {});
    },
    getStoreLocation() {
      this.$store.dispatch("enableLoading");
      this.$run("marketplaces/get-default-address-store")
        .then(res => {
          this.$store.dispatch("disableLoading");
          this.default_address_store = res.data;
        })
        .catch(e => {});
    },
    openModalFindStore() {
      this.$refs.modalFindStore.open();
    },
    openModalCreateAreaByKm() {
      {
        const area_type = "by_km";
        this.$refs.modalCreateArea.openCreateArea(false, area_type);
      }
    },
    changeArea(event) {
      switch (event.type) {
        case "draw.create":
          {
            const area_type = "by_area";
            this.$refs.modalCreateArea.openCreateArea(event, area_type);
          }
          break;
        case "draw.update":
          this.updateArea(event);
          break;
        case "draw.delete":
          this.deleteArea(event);
          break;
        default:
          break;
      }
    },
    confirmRemoveArea(index) {
      this.createConfirmAction({
        icon: "mdi-warning",
        color: "yellow darken-3",
        message: "Deseja remover esta área??",
        action: this.removeArea,
        action_value: index,
        isScoped: true
      });
    },
    removeArea(index) {
      let new_areas = [];
      this.areas.map((a, i) => {
        if (i !== index) {
          new_areas.push(a);
        }
      });
      this.areas = new_areas;
      this.saveDeliveryTax(new_areas);
    },
    saveArea(area) {
      this.areas.push(area);
      this.$refs.modalCreateArea.close();
      this.saveDeliveryTax(this.areas);
    },
    updateAreas(area) {},
    updateArea(area) {},
    deleteArea(area) {},
    saveDeliveryTax(data) {
      this.$store.dispatch("enableLoading");
      this.$run("marketplaces/set-config-delivery", {
        range_delivery_tax: data
      })
        .then(() => {
          this.$store.dispatch("disableLoading");
          this.createGlobalMessage({
            timeout: 6000,
            type: "sucess",
            message: "Configurações de delivery salvas!"
          });
        })
        .catch(e => {});
    },
    selectFeature(feature) {
      if (this.map.getLayer("my-layer") !== undefined) {
        this.map.removeLayer("my-layer");
      }
      if (this.map.getSource("my-data") !== undefined) {
        this.map.removeSource("my-data");
      }

      this.map.addSource("my-data", {
        type: "geojson",
        data: { ...feature.area[0] }
      });
      this.map.addLayer({
        id: "my-layer",
        type: "fill",
        source: "my-data",
        paint: {
          "fill-color": "#8A2BE2",
          "fill-opacity": 0.4
        }
      });
      setTimeout(() => {
        // var bounds = new mapboxgl.LngLatBounds();
        // Encontrar as coordenadas extremas
        const coordinates = feature.area[0].geometry.coordinates[0];
        const bounds = coordinates.reduce(function(bounds, coord) {
          return bounds.extend(coord);
        }, new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));

        // Ajustar o mapa para exibir as coordenadas extremas
        this.map.fitBounds(bounds, { padding: 120 });
        // var source = this.map.getSource("my-data");
        // var bounds = source.getBounds();
        // const geometryLength =
        //   feature.area[0].geometry.coordinates[
        //     feature.area[0].geometry.coordinates[0]
        //   ];
        // console.log("geometry", feature.area[0].geometry.coordinates[0][0]);
        // console.log("geometry len", geometryLength);
        // bounds.extend(feature.area[0].geometry.coordinates[0]);

        // Calcula o centro da caixa delimitadora
        // var center = bounds.getCenter();
        // console.log("center", center);
        // this.map.fitBounds(bounds, { padding: 20 });

        // Define o zoom certo para visualizar toda a fonte de dados
        // var zoom = this.map.getZoomForBounds(bounds);
        // console.log("zoom", zoom);

        // Move o mapa para o centro da fonte de dados com o zoom certo
        // this.map.flyTo({
        //   center: center,
        //   zoom: zoom
        // });
      }, 300);
    },
    getDeliveryConfig() {
      this.map = null;
      this.loading = true;
      this.$store.dispatch("enableLoading");
      this.$run("marketplaces/get-config-delivery")
        .then(res => {
          this.$store.dispatch("disableLoading");
          this.areas = res.data.range_delivery_tax;
          const hasAddress = res.data.address.geometry ? true : false;
          const defaultAddress = hasAddress
            ? [
                res.data.address.geometry.location.lng,
                res.data.address.geometry.location.lat
              ]
            : [-51.084535, -0.002955];
          if (hasAddress) {
            this.default_address_store = res.data.address;
          }
          setTimeout(() => {
            mapboxgl.accessToken =
              "pk.eyJ1IjoicHRrbG9ycmFuIiwiYSI6ImNrM2JzbXRqZTBnZjkzbnFlM3VyYTVzazkifQ.kgDrdfqGfd6XTV5DXfeSwg";
            this.map = new mapboxgl.Map({
              container: "map", // container ID
              // style: "mapbox://styles/ptklorran/clf6pheg000ck01p9njsdb8wx",
              style: "mapbox://styles/ptklorran/clf6pheg000ck01p9njsdb8wx",
              center: defaultAddress,
              zoom: 16 // starting zoom
            });
            this.draw = new MapboxDraw({
              displayControlsDefault: false,
              // Select which mapbox-gl-draw control buttons to add to the map.
              controls: {
                polygon: true,
                line: false,
                // circle: true,
                trash: true
              },
              // Set mapbox-gl-draw to draw by default.
              // The user does not have to click the polygon control button first.
              defaultMode: "draw_polygon"
            });
            const marker = new mapboxgl.Marker()
              .setLngLat(defaultAddress)
              .addTo(this.map);

            const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
              '<h3 class="fonte fonteMini font-weight-bold"> Sua loja </h3>'
            );

            marker.setPopup(popup);
            marker.togglePopup();
            // this.draw.changeMode("draw_circle");
            // const self = this;
            this.map.addControl(this.draw);
            // this.map.on("load", function() {
            //   self.draw.changeMode("draw_circle");
            // });
            this.map.on("draw.create", this.changeArea);
            this.map.on("draw.delete", this.changeArea);
            this.map.on("draw.update", this.changeArea);
            this.loading = false;
          }, 2000);
        })
        .catch(e => {
          setTimeout(() => {
            mapboxgl.accessToken =
              "pk.eyJ1IjoicHRrbG9ycmFuIiwiYSI6ImNrM2JzbXRqZTBnZjkzbnFlM3VyYTVzazkifQ.kgDrdfqGfd6XTV5DXfeSwg";
            this.map = new mapboxgl.Map({
              container: "map", // container ID
              // style: "mapbox://styles/ptklorran/clf6pheg000ck01p9njsdb8wx",
              style: "mapbox://styles/ptklorran/clf6pheg000ck01p9njsdb8wx",
              center: [-51.084535, -0.002955],
              zoom: 16 // starting zoom
            });
            this.draw = new MapboxDraw({
              displayControlsDefault: false,
              // Select which mapbox-gl-draw control buttons to add to the map.
              controls: {
                polygon: true,
                line: false,
                // circle: true,
                trash: true
              },
              // Set mapbox-gl-draw to draw by default.
              // The user does not have to click the polygon control button first.
              defaultMode: "draw_polygon"
            });
            const marker = new mapboxgl.Marker()
              .setLngLat([-51.084535, -0.002955])
              .addTo(this.map);

            const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
              '<h3 class="fonte fonteMini font-weight-bold"> Sua loja </h3>'
            );

            marker.setPopup(popup);
            marker.togglePopup();
            // this.draw.changeMode("draw_circle");
            // const self = this;
            // this.map.addControl(this.draw); //descomenda para que consiga desenhar na tela.
            // this.map.on("load", function() {
            //   self.draw.changeMode("draw_circle");
            // });
            this.map.on("draw.create", this.changeArea);
            this.map.on("draw.delete", this.changeArea);
            this.map.on("draw.update", this.changeArea);
            this.loading = false;
          }, 2000);
        });
    }
  },
  mounted() {
    const script1 = document.createElement("script");
    script1.src =
      "https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-draw/v1.4.0/mapbox-gl-draw.js";
    script1.async = true;
    document.head.appendChild(script1);

    const script2 = document.createElement("script");
    script2.src = "https://unpkg.com/@turf/turf@6/turf.min.js";
    script2.async = true;
    document.head.appendChild(script2);

    const script3 = document.createElement("script");
    script3.src = "https://api.mapbox.com/mapbox-gl-js/v2.13.0/mapbox-gl.js";
    script3.async = true;
    document.head.appendChild(script3);

    const link1 = document.createElement("link");
    link1.rel = "stylesheet";
    link1.href =
      "https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-draw/v1.4.0/mapbox-gl-draw.css";
    document.head.appendChild(link1);

    const link2 = document.createElement("link");
    link2.rel = "stylesheet";
    link2.href = "https://api.mapbox.com/mapbox-gl-js/v2.13.0/mapbox-gl.css";
    document.head.appendChild(link2);
    this.getDeliveryConfig();
  }
};
</script>

<style scoped>
#map {
  width: 100%;
  height: 80vh;
  border-radius: 6px;
  border: 1px solid #e2dfdf;
}
</style>
